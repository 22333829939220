import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Animated string diffing with React and D3",
  "description": "",
  "date": "2016-08-22T08:00:00.000Z",
  "published": "2016-08-22T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/8abc11a0fbe56642beb7c52a210d64bb/sAr4q3L.gif",
        "alt": null
      }}></img></p>
    <p>{`I turned `}<a parentName="p" {...{
        "href": "http://swizec.com/blog/using-d3js-transitions-in-react/swizec/6797"
      }}>{`my animated alphabet example`}</a>{` into an animation of what you’re typing. New characters drop in from above, old characters drop down, and any white space gets squeezed out.`}</p>
    <p>{`Pretty cool, huh? Try it out:`}</p>
    <p>{`Maybe I’m a weirdo nerd, but I could play with that for days. Well, okay, minutes. At least 30 seconds!`}</p>
    <p>{`What I like about this demo is that it’s a great example of declarative transitions built with React. Each letter handles its own animation, so all that the main component has to do is go through a list of characters in a loop and render a `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component for each, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`    render() {
        let { x, y } = this.props,
            transition = d3.transition()
                           .duration(750)
                           .ease(d3.easeCubicInOut);

        return (
            <g transform="{\`translate(\${x}," \${y})\`}="">
                <reacttransitiongroup component="g">
                    {this.state.text.map((l, i) =>
                        <letter letter={l} i={i} key={i} transition={transition}>
                     )}
                </letter></reacttransitiongroup>
            </g>
        );
    }
`}</code></pre>
    <p>{`Declaring a root `}<inlineCode parentName="p">{`transition`}</inlineCode>{` ensures individual letter transitions are synced. Rendering them in a `}<inlineCode parentName="p">{`ReactTransitionGroup`}</inlineCode>{` gives us some additional lifecycle methods, and each letter needs its index so that it can calculate horizontal positioning because it lacks situational awareness.`}</p>
    <p>{`Then, each `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component takes care of its own enter/update/exit transitions inside special lifecycle hooks. `}<inlineCode parentName="p">{`componentWillEnter`}</inlineCode>{` for the enter animation, `}<inlineCode parentName="p">{`componentWillReceiveProps`}</inlineCode>{` for update, and `}<inlineCode parentName="p">{`componentWillLeave`}</inlineCode>{` for exit. `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-enter-exit-transitions/blob/master/src/components/Letter.jsx"
      }}>{`The whole component`}</a>{` is just 59 standard lines of code.`}</p>
    <p>{`You can read my previous 2000-word article to learn the details about `}<a parentName="p" {...{
        "href": "http://swizec.com/blog/using-d3js-transitions-in-react/swizec/6797"
      }}>{`using D3 transitions with React components`}</a>{`. I’ve cleaned up the code since then, but the gist hasn’t changed. You can see the current code in my `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-enter-exit-transitions"
      }}>{`github repository`}</a>{`.`}</p>
    <p>{`The `}<em parentName="p">{`key`}</em>{` mystery was choosing the right key prop for each `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component. That part was hard. It took me two whole nights!`}</p>
    <h2 {...{
      "id": "choosing-the-key-prop"
    }}>{`Choosing the key prop`}</h2>
    <p>{`The key prop is how React identifies your components. For the most part, you don’t have to worry about choosing correctly. Make it unique and you’re done. React can tell your components apart, which lets it do the fancy diffing stuff, and everything Just Works™.`}</p>
    <p>{`Sometimes, though, the type of uniqueness matters.`}</p>
    <p>{`For example: if you use the letter itself for the key prop, this example breaks down. It won’t even display input value correctly.`}</p>
    <p><img parentName="p" {...{
        "src": "/016cda9ad6c8688a4fdad360167a90d4/zkgtGiR.gif",
        "alt": null
      }}></img></p>
    <p>{`You only get one chance to show each character. Repeat it and React won’t care - it’s the same component as far as React is concerned. Empty spaces show up because we use character index from the original string to calculate horizontal positioning.`}</p>
    <p>{`?`}</p>
    <p>{`Okay then, what if we use the index? Each letter has its own, so that’s good, and it’s easy to set up, so that’s great.`}</p>
    <p><img parentName="p" {...{
        "src": "/f61d745c7090fbf3ed29fcff867c8067/gWKujjz.gif",
        "alt": null
      }}></img></p>
    <p>{`Waaaait a minute. That’s no good! Appending new characters to the end looks great, deleting characters from the end works too, but as soon as you mess around in the middle, all hell breaks loose.`}</p>
    <p>{`React uses indexes to identify `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` instances, so only the last character animates. The fancy-pants diffing algorithm can do the diffing, but it doesn’t understand the context.`}</p>
    <p>{`Ugh.`}</p>
    <p>{`We have to help React out and implement our own string diffing algorithm. If you’ve ever gone to a computer science class or three, this should be a familiar problem. It’s a variation of the much researched `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Longest_common_subsequence_problem"
      }}>{`longest subsequence problem`}</a>{`.`}</p>
    <h2 {...{
      "id": "a-simple-string-diffing-algorithm"
    }}>{`A simple string diffing algorithm`}</h2>
    <p>{`The full longest common subsequence problem is a hairy beast with decades of research behind it. Efficient solutions are used in everything from version control systems to bioinformatics.`}</p>
    <p>{`A few assumptions make it easier in our case:`}</p>
    <ul>
      <li parentName="ul">{`changes only happen at the caret`}</li>
      <li parentName="ul">{`there is only 1 caret`}</li>
      <li parentName="ul">{`there will only ever be 1 change`}</li>
    </ul>
    <p>{`A linear-complexity algorithm will do :)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/cdec4/iknWGlq.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.88789237668162%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'300\\'%20viewBox=\\'0%200%20400%20300\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%206c0%205%200%206%202%205%202%200%202%200%202%204l1%204v-8h14l18-2c5%200%205%202-1%205-5%204-7%207-7%2010%200%206%208%207%2051%209a1084%201084%200%2001101%208c9%202%2030%204%2045%204l23%201c16%201%2043-1%2054-6l5-2-3-2-2-2%202-1%204-2c-1%200-2%200-1-1l5-1h5l3-1c2%200%202%200%200-2l-2-1c-2%201-16-2-15-4h5l4%201c2-1-10-5-15-6l-12-3-16-1a457%20457%200%2001-59-5h-6c-2%202-7%201-30%200a1532%201532%200%2000-35-4c0-2%201-2%208-2L78%200H0v6\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/ca0a1/iknWGlq.webp 223w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/75680/iknWGlq.webp 445w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/8d1ba/iknWGlq.webp 890w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/17ad2/iknWGlq.webp 1108w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/853b2/iknWGlq.jpg 223w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/0a4fe/iknWGlq.jpg 445w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/7f80b/iknWGlq.jpg 890w", "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/cdec4/iknWGlq.jpg 1108w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba81dd0be5a3a9dc7c9cb9c685522dbc/7f80b/iknWGlq.jpg",
              "alt": "iknWGlq",
              "title": "iknWGlq",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assuming there’s only one change at a time works even when you take copy/paste into account. Users can’t copy/paste disparate chunks of text at the same time. It has to be connected. So even if the change uses the same text, it acts as a big remove followed by an insert.`}</p>
    <p>{`Our game plan is thus:`}</p>
    <ul>
      <li parentName="ul">{`assign a unique ID to each new character`}</li>
      <li parentName="ul">{`maintain `}<inlineCode parentName="li">{`(char, id)`}</inlineCode>{` pairs for the same portion`}</li>
      <li parentName="ul">{`create or drop `}<inlineCode parentName="li">{`(char, id)`}</inlineCode>{` pairs for the changed portion`}</li>
      <li parentName="ul">{`shift `}<inlineCode parentName="li">{`(char, id)`}</inlineCode>{` pairs to new indexes for the shifted portion`}</li>
    </ul>
    <p>{`The implementation took me 55 lines of code. You can see it in its entirety at the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-enter-exit-transitions/blob/master/src/components/FancyText.jsx"
      }}>{`github repository`}</a>{`. There are 7 steps.`}</p>
    <h3 {...{
      "id": "step-1---prep-the-vars"
    }}>{`Step 1 - prep the vars`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()
const oldText = this.state.textWithIds;
const newText = newProps.text.split("");
let indexOfChange = 0,
  sizeOfChange = 0,
  newLastId = this.state.lastId;
`}</code></pre>
    <p><inlineCode parentName="p">{`oldText`}</inlineCode>{` is the current array of `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pairs, `}<inlineCode parentName="p">{`newText`}</inlineCode>{` becomes an array of characters in the new string. We initiate `}<inlineCode parentName="p">{`indexOfChange`}</inlineCode>{` and `}<inlineCode parentName="p">{`sizeOfChange`}</inlineCode>{` to `}<inlineCode parentName="p">{`0`}</inlineCode>{`. `}<inlineCode parentName="p">{`this.state.lastId`}</inlineCode>{` is where we keep a running tally of new characters. Think of it as an auto increment ID.`}</p>
    <h3 {...{
      "id": "step-2---find-the-change"
    }}>{`Step 2 - find the change`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()
// find change
for (
  ;
  newText[indexOfChange] ==
  (oldText[indexOfChange] && oldText[indexOfChange][0]);
  indexOfChange++
);
`}</code></pre>
    <p>{`Looks like old school C code, doesn’t it? We keep incrementing `}<inlineCode parentName="p">{`indexOfChange`}</inlineCode>{` until we find a character mismatch between the new and old text. Then we know where the insertion or deletion begins.`}</p>
    <h3 {...{
      "id": "step-3---calculating-size-of-change"
    }}>{`Step 3 - calculating size of change`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()

// calculate size of change
if (newText.length > oldText.length) {
  while (
    newText[indexOfChange + sizeOfChange] !=
      (oldText[indexOfChange] && oldText[indexOfChange][0]) &&
    indexOfChange + sizeOfChange < newText.length
  ) {
    sizeOfChange = sizeOfChange + 1;
  }
} else {
  while (
    newText[indexOfChange] !=
      (oldText[indexOfChange + sizeOfChange] &&
        oldText[indexOfChange + sizeOfChange][0]) &&
    indexOfChange + sizeOfChange < oldText.length
  ) {
    sizeOfChange = sizeOfChange + 1;
  }
}
`}</code></pre>
    <p>{`Here we have two different branches - one for insertion and one for deletion. They both use the same principle and have small differences based on which characters to compare.`}</p>
    <p>{`We keep increasing `}<inlineCode parentName="p">{`sizeOfChange`}</inlineCode>{` until `}<inlineCode parentName="p">{`indexOfChange+sizeOfChange`}</inlineCode>{` either finds a character that matches in both strings, or until it runs out of characters to check. The difference between insertion and deletion is that we’re shifting the lookup index of either `}<inlineCode parentName="p">{`newText`}</inlineCode>{` or `}<inlineCode parentName="p">{`oldText`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "step-4---copying-same-section"
    }}>{`Step 4 - copying same section`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()

// use existing ids up to point of change
d3.range(0, indexOfChange).forEach((i) => (newText[i] = oldText[i]));
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.range`}</inlineCode>{` creates an array of indexes from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`indexOfChange`}</inlineCode>{`. We loop through it and overwrite `}<inlineCode parentName="p">{`newText`}</inlineCode>{` with existing `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pairs from `}<inlineCode parentName="p">{`oldText`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "step-5---add-new-char-id-pairs"
    }}>{`Step 5 - add new (char, id) pairs`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()

      // use new ids for additions
        if (newText.length > oldText.length) {
            d3.range(indexOfChange, indexOfChange+sizeOfChange).forEach((i) => {
                let letter = newText[i];
                newText[i] = [letter, newLastId++];
            });
`}</code></pre>
    <p>{`If the change is an insertion, we go from `}<inlineCode parentName="p">{`indexOfChange`}</inlineCode>{` to `}<inlineCode parentName="p">{`indexOfChange+sizeOfChange`}</inlineCode>{`, create new `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pairs, and override `}<inlineCode parentName="p">{`newText`}</inlineCode>{` at each index. To create each ID, we take `}<inlineCode parentName="p">{`newLastId`}</inlineCode>{` and increment it.`}</p>
    <p>{`Just like an auto increment index. It’s a running count of new characters that never decrements.`}</p>
    <h3 {...{
      "id": "step-6---shift-remaining-char-id-pairs"
    }}>{`Step 6 - shift remaining (char, id) pairs`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()

if (newText.length > oldText.length) {
  // insertion …

  // use existing ids from change to end
  d3.range(indexOfChange + sizeOfChange, newText.length).forEach(
    (i) => (newText[i] = oldText[i - sizeOfChange])
  );
} else {
  // use existing ids from change to end, but skip what's gone
  d3.range(indexOfChange, newText.length).forEach(
    (i) => (newText[i] = oldText[i + sizeOfChange])
  );
}
`}</code></pre>
    <p>{`Here we again have two branches: one for insertion, one for deletion. Both copy `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pairs from `}<inlineCode parentName="p">{`oldText`}</inlineCode>{` to `}<inlineCode parentName="p">{`newText`}</inlineCode>{`, but the shift happens in different directions.`}</p>
    <p>{`When inserting, we have to shift the index by `}<inlineCode parentName="p">{`sizeOfChange`}</inlineCode>{` to the right. When deleting, we shift the index to the left.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> componentWillReceiveProps()

this.setState({ text: newProps.text, textWithIds: newText, lastId: newLastId });
`}</code></pre>
    <p>{`We have an updated list of `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pairs in `}<inlineCode parentName="p">{`newText`}</inlineCode>{`. It reflects the updated text value while keeping all ID assignments stable throughout the process. Pretty neat.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`this.setState`}</inlineCode>{` so that when React calls `}<inlineCode parentName="p">{`render()`}</inlineCode>{` on our `}<inlineCode parentName="p">{`FancyText`}</inlineCode>{` component, it will use the updated state. `}<inlineCode parentName="p">{`componentWillReceiveProps`}</inlineCode>{` is the only lifecycle method where calling `}<inlineCode parentName="p">{`setState`}</inlineCode>{` does not trigger a re-render.`}</p>
    <p>{`Neat, huh?`}</p>
    <h3 {...{
      "id": "step-75---use-the-char-id-pairs"
    }}>{`Step 7.5 - use the (char, id) pairs`}</h3>
    <p>{`There’s one more thing. We have to update how rendering happens. Invoking the `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component looks like this now:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// FancyText.jsx -> render()

 return (
            <g transform="{\`translate(\${x}," \${y})\`}="">
                <reacttransitiongroup component="g">
                    {this.state.textWithIds.map(([l, id], i) =>
                        <letter letter={l} i={i} key={id} transition={transition}>
                     )}
                </letter></reacttransitiongroup>
            </g>
        );
`}</code></pre>
    <p>{`Instead of iterating over `}<inlineCode parentName="p">{`this.props.text`}</inlineCode>{`, we iterate over `}<inlineCode parentName="p">{`this.state.textWithIds`}</inlineCode>{`. For each iteration, we take the `}<inlineCode parentName="p">{`(char, id)`}</inlineCode>{` pair, destructure it, and use the `}<inlineCode parentName="p">{`id`}</inlineCode>{` for our key prop.`}</p>
    <p>{`And that’s it, our animated typing example looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/8abc11a0fbe56642beb7c52a210d64bb/11aZVdH.gif",
        "alt": null
      }}></img></p>
    <p>{`Wasn’t that fun?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      